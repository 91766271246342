import React from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

export default function LogoLoader({ cardImg }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading ? <Skeleton count={1} height="70px" /> : null}
      <img
        src={`${process.env.REACT_APP_IMAGE_Link}${cardImg}`}
        className="card_logo_img"
        alt="card pic"
        style={isLoading ? { display: "none" } : {}}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
}
