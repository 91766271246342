import { useFormik } from "formik";
import React, { useState } from "react";

import { toast } from "react-toastify";

import AdminListService from "../../../services/admin-list.service";
import "./login.css";
import Logo from "../../../Images/logo.webp";

import { Helmet } from "react-helmet";
import { forgot } from "../../../schema";
import ReactLoading from "react-loading";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";

export default function ForgotPass() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const sendMail = async (values) => {
    setLoading(true);
    try {
      const response = await AdminListService.forgotPass(values);

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/admin/login", { replace: true });
      }, 1000);
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (
        err?.response?.data?.error &&
        typeof err?.response?.data?.error === "string"
      ) {
        toast.error(err?.response?.data?.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: forgot,
      onSubmit: (values, action) => {
        sendMail(values);
      },
    });

  return (
    <div className="login-form">
      <Helmet>
        <title>Admin Panel</title>
      </Helmet>
      <div className="container-login100">
        <div className="wrap-login100">
          <img src={Logo} alt="" className="login-logo" />
          {/* <span className="login100-form-title"> Account Login </span> */}
          <form
            className="login100-form validate-form"
            onSubmit={handleSubmit}
            noValidate
          >
            <div
              className={`wrap-input100 validate-input ${
                errors.email && touched.email ? "alert-validate" : ""
              } `}
              data-validate={errors.email}
              id="emailforgot"
            >
              <input
                className={`input100 ${values.email !== "" ? "has-val" : ""}`}
                type="text"
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  $("#emailforgot").removeClass("input-focus");
                }}
                onFocus={() => {
                  $("#emailforgot").addClass("input-focus");
                }}
                name="email"
                value={values.email || ""}
                placeholder="Email"
              />
              <span
                className="focus-input100"
                data-placeholder="&#xf007;"
              ></span>
              <Link to="/admin/login">Login</Link>
            </div>

            <div className="container-login100-form-btn">
              <button className="login100-form-btn" type="submit">
                Recover Password
              </button>
            </div>
          </form>
        </div>
      </div>
      {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#f68d2d"
          className="bar-loader"
        />
      )}
    </div>
  );
}
