import * as Yup from "yup";

export const signImSchema = Yup.object({
  username: Yup.string().required("Enter UserName"),
  password: Yup.string().required("Enter Password"),
});
export const forgot = Yup.object({
  email: Yup.string().email("Invalid Email").required("Enter Email"),
});

export const updateProfile = Yup.object({
  email: Yup.string()
    .email("Invalid E-mail")
    .required("Please enter the e-mail"),
  old_password: Yup.string().when({
    is: (old_password) => old_password,
    then: Yup.string().required("Please enter th current password"),
    otherwise: Yup.string().notRequired(),
  }),
  password: Yup.string().when("old_password", {
    is: (old_password) => old_password,
    then: Yup.string().required("Please enter the new password"),
    otherwise: Yup.string().notRequired(),
  }),

  confirm_password: Yup.string()
    .when("password", {
      is: (password) => password,
      then: Yup.string().required("Please verify the new password"),
      otherwise: Yup.string().notRequired(),
    })
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const websiteChange = Yup.object({
  email: Yup.string().email("Invalid Email").required("Please enter the email"),
  phone: Yup.string()
    .matches(/^[0-9- ]+$/, "Invalid phone number")
    .min(12, "The phone must contain 12 characters.")
    .required("Please enter the phone"),
});
export const updateNewsleter = Yup.object({
  subject: Yup.string().required("Please enter the subject"),
  body: Yup.string().required("Please enter in the body"),
});

export const updateGift = Yup.object({
  title: Yup.string().required("Please enter the title"),
  description: Yup.string().required("Please enter the description "),
});

export const newGiftAdd = Yup.object({
  title: Yup.string().required("Please enter the title"),
  description: Yup.string().required("Please enter the description "),
  image: Yup.string().required("Please select the image "),
});

export const quizSearch = Yup.object({
  fieldtype: Yup.string().required("Please Select a Column"),
  searchval: Yup.string().required("Please fill the search field"),
});

export const addEmailFun = Yup.object({
  email: Yup.string().email("Invalid Email").required("Please enter the email"),
});

export const addLogoFun = Yup.object({
  image: Yup.string().required("Please select the logo"),
});

export const updateVoucher = Yup.object({
  Company: Yup.string()
    .min(3, "The ccmpany must be at least 3 characters.")
    .max(50, "The ccmpany must not greater then 50 characters.")
    .required("The ccmpany field is required"),
  Certificate: Yup.string()
    .min(11, "The certificate must be at least 11 characters.")
    .required("The certificate field is required.	"),

  First: Yup.string()
    .min(3, "The first field must be at least 3 characters.")
    .max(50, "The first field must not greater then 50 characters.")
    .required("The first field is required.	"),

  State: Yup.string()
    .min(2, "The city field must be at least 2 characters.")
    .max(20, "The city field must not greater then 20 characters.")
    .required("The state field is required."),
  Job: Yup.number()
    .min(0, "The job field is required.")
    .required("The job field is required."),
  Amount: Yup.number().notRequired(),
  Denomination: Yup.number().notRequired(),
  shipCost: Yup.number().notRequired(),

  Concatenated: Yup.string()
    .min(11, "The concatenated must be at least 11 characters.")
    .required("The concatenated field is required."),

  Last: Yup.string()
    .min(3, "The last field must be at least 3 characters.")
    .max(50, "The last field must not greater then 50 characters.")
    .required("The last field is required."),
  City: Yup.string()
    .min(3, "The city field must be at least 2 characters.")
    .max(30, "The city field must not greater then 30 characters.")
    .required("The city field is required."),
  Zip: Yup.string().required("The zip field is required."),
});

export const importDocument = Yup.object({
  survey: Yup.string().required("Please select the survey option"),
  logo: Yup.string().required("Please select the logo option"),
  file: Yup.string().required("Please select the file"),
});
