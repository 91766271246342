import React from "react";
import img from "../../Images/mouse.png";

const Samp = () => {
  return (
    <div className="row">
      <div className="col-md-1 col-sm-2">
        <img src={img} />
      </div>
      <div className="col-md-11">
        <p>
          Once you have your unique code, please visit
          <a href="www.ashleygifts.biz"> www.ashleygifts.biz</a> to redeem your
          reward. For those that do not have an email address, a mail in
          redemption option will also be available.
        </p>
      </div>
    </div>
  );
};
export default Samp;
