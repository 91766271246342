import React from "react";
import Logo from "../../Images/logo.webp";

const Header = () => {
  return (
    <div className="container-fluid">
      <div className="">
        <div className="row">
          <div className="col-md-12 pos-rel">
            <div className="logo">
              <img src={Logo} className="img-responsive" 
              style={{ width: "auto", maxWidth: "360px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
