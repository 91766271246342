import React, { useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-extended/lib/bootstrap-extended.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-loading-skeleton/dist/skeleton.css";
import "./App.css";
import $ from "jquery";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Header from "./Components/admin/includes/Header";

import Login from "./Components/admin/login/Login";

import { useDispatch, useSelector } from "react-redux";
import NotFound from "./Components/admin/includes/NotFound";

import { bindActionCreators } from "redux";
import { actionCreaters } from "./Redux";

import { ToastContainer, toast } from "react-toastify";

import axios from "axios";
import Error429 from "./Components/admin/includes/Error429";
import Dashboard from "./Components/admin/Dashboard/Dashboard";
import jwtDecode from "jwt-decode";
import ForgotPass from "./Components/admin/login/ForgotPass";

import Profile from "./Components/admin/Dashboard/Profile";

import ViewGifts from "./Components/admin/ManageGifts/ViewGifts";
import ManageWebsite from "./Components/admin/ManageWebsite/ManageWebsite";
import ManageNewsletter from "./Components/admin/ManageWebsite/ManageNewsletter";
import ImportCsv from "./Components/admin/ManageDocument/ImportCsv";
import EditGift from "./Components/admin/ManageGifts/EditGift";
import AddGift from "./Components/admin/ManageGifts/AddGift";
import ViewOrders from "./Components/admin/ManageOrders/ViewOrders";
import ViewArchiveOrders from "./Components/admin/ManageOrders/ViewArchiveOrders";
import ViewUsedVouchers from "./Components/admin/Vouchers/ViewUsedVouchers";
import ViewVouchers from "./Components/admin/Vouchers/ViewVouchers";
import EditVoucher from "./Components/admin/Vouchers/EditVoucher";
import ViewEmails from "./Components/admin/ManageEmails/ViewEmails";
import EditEmail from "./Components/admin/ManageEmails/EditEmail";
import AddEmail from "./Components/admin/ManageEmails/AddEmail";
import ViewArchiveVouchers from "./Components/admin/Vouchers/ViewArchiveVouchers";
import Editlogo from "./Components/admin/ManageLgos/Editlogo";
import AddLogo from "./Components/admin/ManageLgos/AddLogo";
import ViewLogos from "./Components/admin/ManageLgos/ViewLogos";
import ManageJobs from "./Components/admin/ManageJobs/ManageJobs";
import JobDetail from "./Components/admin/ManageJobs/JobDetail";
import ViewSurvey from "./Components/admin/ManageSurvey/ViewSurvey";
import ViewArchiveSurvey from "./Components/admin/ManageSurvey/ViewArchiveSurvey";
import RegisterForm from "./Components/user/RegisterForm";
import Thankyou from "./Components/user/Thankyou";
import UserForm from "./Components/user/UserForm";
import PrivacyPolicy from "./Components/user/PrivacyPolicy";
import { useLayoutEffect } from "react";
import CertdDtails from "./Components/user/CertdDtails";
import NoInternet from "./Components/user/NoInternet";
import Test from "./Components/admin/ManageDocument/Test";
import Eye from "./Components/admin/ManageDocument/Eye";

function App() {
  const navigate = useNavigate();
  const locat = useLocation();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  useEffect(() => {
    if (locat.pathname.match(/\/{2,}/)) {
      if (locat.pathname.indexOf("admin/") > -1) {
        window.location.href = "/admin/login";
      } else {
        window.location.href = "/";
      }
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 429) {
          window.location.href = "/error-429";
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      locat.pathname !== "/" &&
      locat.pathname !== "/thank-you" &&
      locat.pathname !== "/user-form" &&
      locat.pathname !== "/cert_details" &&
      locat.pathname !== "/privacy-policy" &&
      locat.pathname !== "/thank-you/" &&
      locat.pathname !== "/user-form/" &&
      locat.pathname !== "/cert_details/" &&
      locat.pathname !== "/privacy-policy/"
    ) {
      if (adminAccessToken) {
        const backToTop = () => {
          return (
            $("html, body").animate(
              {
                scrollTop: 0,
              },
              600
            ),
            !1
          );
        };
        backToTop();
        const decoded = jwtDecode(adminAccessToken);
        const currentTime = Math.floor(new Date().getTime() / 1000);

        if (currentTime > decoded.exp) {
          toast.error("Request time out!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          userActions.logOut("adminLogOut");
          navigate("/admin/login", { replace: true });
        } else if (
          locat.pathname === "/admin" ||
          locat.pathname === "/admin/" ||
          locat.pathname === "/login" ||
          locat.pathname === "/login/" ||
          locat.pathname === "/admin/login" ||
          locat.pathname === "/admin/login/"
        ) {
          navigate("/admin/dashboard", { replace: true });
        }
      } else {
        if (
          locat.pathname.indexOf("admin") > -1 &&
          locat.pathname !== "/admin/forgot-pass"
        ) {
          navigate("/admin/login", { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [locat.pathname]);

  useLayoutEffect(() => {
    localStorage.setItem("isVoucher", false);
    if (
      locat.pathname === "/" ||
      locat.pathname === "/thank-you" ||
      locat.pathname === "/user-form" ||
      locat.pathname === "/cert_details" ||
      locat.pathname === "/privacy-policy" ||
      locat.pathname === "/thank-you/" ||
      locat.pathname === "/user-form/" ||
      locat.pathname === "/cert_details/" ||
      locat.pathname === "/privacy-policy/"
    ) {
      $("html").attr("class", "img-bg");
    }
  }, [locat.pathname]);

  return (
    <NoInternet>
      <ToastContainer />

      {locat.pathname !== "/admin/login" &&
      locat.pathname !== "/admin/" &&
      locat.pathname !== "/admin" &&
      locat.pathname !== "/admin/forgot-pass" &&
      locat.pathname !== "/admin/forgot-pass/" &&
      locat.pathname !== "/" &&
      locat.pathname !== "/thank-you" &&
      locat.pathname !== "/user-form" &&
      locat.pathname !== "/cert_details" &&
      locat.pathname !== "/privacy-policy" &&
      locat.pathname !== "/thank-you/" &&
      locat.pathname !== "/user-form/" &&
      locat.pathname !== "/cert_details/" &&
      locat.pathname !== "/privacy-policy/" ? (
        <Header />
      ) : null}
      <Routes>
        <Route path="/" element={<RegisterForm />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="/user-form" element={<UserForm />} />
        <Route path="/cert_details" element={<CertdDtails />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/admin" element={<Login />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/forgot-pass" element={<ForgotPass />} />
        <Route
          path="/admin/dashboard"
          element={adminAccessToken ? <Dashboard /> : <Login />}
        />
        <Route
          path="/admin/profile"
          element={adminAccessToken ? <Profile /> : <Login />}
        />
        <Route
          path="/admin/manage-website"
          element={adminAccessToken ? <ManageWebsite /> : <Login />}
        />
        <Route
          path="/admin/manage-newsletter"
          element={adminAccessToken ? <ManageNewsletter /> : <Login />}
        />
        <Route
          path="/admin/import-csv"
          element={adminAccessToken ? <ImportCsv /> : <Login />}
        />

        <Route
          path="/admin/import-test"
          element={adminAccessToken ? <Test /> : <Login />}
        />
        <Route
          path="/admin/password-eye"
          element={adminAccessToken ? <Eye /> : <Login />}
        />

        <Route
          path="/admin/manage-gifts"
          element={adminAccessToken ? <ViewGifts /> : <Login />}
        />
        <Route
          path="/admin/add-gifts"
          element={adminAccessToken ? <AddGift /> : <Login />}
        />
        <Route
          path="/admin/edit-gifts/:id"
          element={adminAccessToken ? <EditGift /> : <Login />}
        />

        <Route
          path="/admin/orders"
          element={adminAccessToken ? <ViewOrders /> : <Login />}
        />
        <Route
          path="/admin/archOrder"
          element={adminAccessToken ? <ViewArchiveOrders /> : <Login />}
        />
        <Route
          path="/admin/vouchers"
          element={adminAccessToken ? <ViewVouchers /> : <Login />}
        />
        <Route
          path="/admin/used-vouchers"
          element={adminAccessToken ? <ViewUsedVouchers /> : <Login />}
        />
        <Route
          path="/admin/edit-voucher/:id"
          element={adminAccessToken ? <EditVoucher /> : <Login />}
        />
        <Route
          path="/admin/archive-vouchers"
          element={adminAccessToken ? <ViewArchiveVouchers /> : <Login />}
        />

        <Route
          path="/admin/manage-email"
          element={adminAccessToken ? <ViewEmails /> : <Login />}
        />
        <Route
          path="/admin/add-email"
          element={adminAccessToken ? <AddEmail /> : <Login />}
        />
        <Route
          path="/admin/edit-email/:id"
          element={adminAccessToken ? <EditEmail /> : <Login />}
        />
        <Route
          path="/admin/manage-Logo"
          element={adminAccessToken ? <ViewLogos /> : <Login />}
        />
        <Route
          path="/admin/add-Logo"
          element={adminAccessToken ? <AddLogo /> : <Login />}
        />
        <Route
          path="/admin/edit-Logo/:id"
          element={adminAccessToken ? <Editlogo /> : <Login />}
        />

        <Route
          path="/admin/manage-job"
          element={adminAccessToken ? <ManageJobs /> : <Login />}
        />
        <Route
          path="/admin/job-detail/:job"
          element={adminAccessToken ? <JobDetail /> : <Login />}
        />

        <Route
          path="/admin/view-survey"
          element={adminAccessToken ? <ViewSurvey /> : <Login />}
        />

        <Route
          path="/admin/archive_survey"
          element={adminAccessToken ? <ViewArchiveSurvey /> : <Login />}
        />

        <Route path="/error-429" element={<Error429 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* {locat.pathname.match(/\/{2,}/) ? 
      <div className="showPage"></div> : null} */}
      {locat.pathname.match(/\/{2,}/) ? (
        <div className="fix-white"></div>
      ) : null}
    </NoInternet>
  );
}

export default App;
