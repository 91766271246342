import React from "react";
import img from "../../Images/spam.png";

const Samp = () => {
  return (
    <div className="row">
      <div className="col-md-1 col-sm-2">
        <img src={img} />
      </div>
      <div className="col-md-11">
        <p className="cat">
        <i>Don’t forget to check your Spam filter!</i>
        </p>
      </div>
    </div>
  );
};
export default Samp;
