import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";

function CertdDtails(props) {
  const isVoucher = localStorage.getItem("cert_details");
  const navigate = useNavigate();
  var cert_details = secureLocalStorage.getItem("cert_details");
  var RedemObject = JSON.parse(cert_details);

  useEffect(() => {
    if (isVoucher !== "true" || isVoucher === undefined || isVoucher === null) {
      navigate("/");
    } else {
      localStorage.setItem("cert_details", false);
    }
  }, []);
  return (
    <>
      <div className="container-fluid">
        <Header />
        {/* <div className="header_lower">CITY Furniture and Elite Rewards are proud supporters of the American Heart Association</div> */}
        <div className="white-box">
          <h1>Reward Card Redemption Site</h1>
          <hr />
          <h2>Contact Information</h2>
          <hr />
          <form className="form-outer register clearfix">
            <div className="row">
              <div className="col-md-6">
                <label>
                  <strong>First Name </strong>
                  <br />
                  {RedemObject?.fname ? RedemObject.fname : ""}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>Last Name</strong>
                  <br />
                  {RedemObject?.lname ? RedemObject.lname : ""}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>Address</strong>
                  <br />
                  {RedemObject?.address ? RedemObject.address : ""}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>Address2</strong>
                  <br />
                  {RedemObject?.address2 ? RedemObject.address2 : ""}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>City </strong>
                  <br />
                  {RedemObject?.city ? RedemObject.city : ""}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  <strong>State </strong>
                  <br />
                  {RedemObject?.state ? RedemObject.state : ""}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>Zip Code</strong>
                  <br />
                  {RedemObject?.zip ? RedemObject.zip : ""}
                </label>
              </div>{" "}
              <div className="col-md-6">
                <label>
                  <strong>Phone No </strong>
                  <br />
                  {RedemObject?.phone ? RedemObject.phone : ""}
                </label>
              </div>
              <div className="clearfix"></div>
              <div className="col-md-6">
                <label>
                  <strong>Email </strong>
                  <br />
                  {RedemObject?.email ? RedemObject.email : ""}
                </label>
              </div>
              <hr />
              <h2>Ashley Gifts </h2>
              <hr />
              <div className="check-boexs cen">
                <div className="reward_cert">
                  <span className="insdex">
                    {RedemObject?.cardName ? RedemObject.cardName : ""}
                  </span>
                  {RedemObject?.cardImage && (
                    <img
                      src={
                        process.env.REACT_APP_API_GIFT_IMAGES +
                        RedemObject?.cardImage
                      }
                      className="imgcolor img-responsive"
                    />
                  )}
                </div>
              </div>
            </div>
            <Link to="/" className="btn btn-md btn-primary">
              Go Back
            </Link>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default CertdDtails;
