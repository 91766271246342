import React from "react";
import img from "../../Images/shipping.png";

const Pobox = () => {
  return (
    <div className="row">
      <div className="col-md-1 col-sm-2">
        <img src={img} />
      </div>
      <div className="col-md-11">
        {/* <b className="cat">
          Click order and you will receive an email confirmation
        </b> */}
        <p className="cat">
          Please note: Make sure to enter a <b>&nbsp;physical shipping address</b>, we{" "}
          <b>&nbsp;can NOT</b>&nbsp;ship your reward to a PO Box.
        </p>
      </div>
    </div>
  );
};
export default Pobox;
