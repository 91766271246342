import React from "react";
import img from "../../Images/binoculars.png";
import { Link } from "react-router-dom";
const EmailBox = () => {
  return (
    <div className="row">
      <div className="col-md-1 col-sm-2">
        <img src={img} />
      </div>
      <div class="col-md-11">
        Please be on the lookout for an email from{" "}
        <b>
          <Link to="mailto:info@ashleygifts.biz">info@ashleygifts.biz</Link>
        </b>{" "}
        containing your voucher and code with a subject line of{" "}
        <b>“Your Ashley Reward Has Arrived!”</b>, or look for a letter with your
        paper voucher.{" "}
      </div>
    </div>
  );
};
export default EmailBox;
