import React from "react";
import img from "../../Images/question.png";

const ContactUs = () => {
  return (
    <div className="row">
      <div className="col-md-1 col-sm-2">
        <img src={img} />
      </div>
      <div className="col-md-11">
        <b>
          For questions, please email us at{" "}
          <a href="mailto:starcustomercare@eliterewards.biz">
            starcustomercare@eliterewards.biz
          </a>{" "}
          or call our Customer Service line at{" "}
          <a href="tel:866-354-8321">866-354-8321</a> and one of the customer
          service representatives will be happy to assist you (M-F 9am-5pm EST).
        </b>
      </div>
    </div>
  );
};
export default ContactUs;
