import React, { useEffect, useState } from "react";
const UserFormHeader = () => {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    setLogo(localStorage.getItem("Logo"));
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="logo">
            <img
              src={
                logo && logo !== ""
                  ? process.env.REACT_APP_IMAGE_Link + logo
                  : ""
              }
              className="img-responsive"
              alt="site logo"
              style={{ width: "auto", maxWidth: "360px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserFormHeader;
