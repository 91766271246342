import axios from "axios";
import he from "he";

const API_URL = process.env.REACT_APP_API_Link;

const headers = {
  "Content-Type": "multipart/form-data",
};

const headers2 = {
  "Content-Type": "application/json",
};

const loginAdmin = (values) => {
  return axios.post(
    API_URL + "admin-login",
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers2,
    }
  );
};

const forgotPass = (values) => {
  return axios.post(
    API_URL + "admin-forgot",
    {
      email: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const getCounts = () => {
  return axios.get(
    API_URL + "all-counters",

    {
      headers: headers2,
    }
  );
};

const latestOrder = () => {
  return axios.get(
    API_URL + "latest-orders",

    {
      headers: headers2,
    }
  );
};
const orderArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `archive-order/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const getProfile = (accesstoken) => {
  return axios.get(
    API_URL + "profile",

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accesstoken}`,
      },
    }
  );
};

const profileUpdate = (values, id) => {
  return axios.put(
    API_URL + `profile/${id}`,
    {
      email: values.email,
      password: values.old_password,
      new_password: values.password,
    },
    {
      headers: headers2,
    }
  );
};

const getWebsiteInfo = () => {
  return axios.get(
    API_URL + "website",

    {
      headers: headers2,
    }
  );
};

const websiteUpdate = (values) => {
  return axios.put(
    API_URL + "website",
    {
      email: values.email,
      phone: values.phone,
    },
    {
      headers: headers2,
    }
  );
};

const getAllYears = () => {
  return axios.get(API_URL + `list-years`, {
    headers: headers2,
  });
};

const getAllGifts = () => {
  return axios.get(API_URL + `all-products`, {
    headers: headers2,
  });
};
const getAllLogos = () => {
  return axios.get(API_URL + `all-logos`, {
    headers: headers2,
  });
};

const getGifts = (page, limit, values) => {
  return axios.get(
    API_URL +
      `products?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleGifts = (id, admin_id) => {
  return axios.delete(API_URL + "product/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllGifts = (admin_id) => {
  return axios.delete(API_URL + "products", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const getGiftById = (id) => {
  return axios.get(
    API_URL + "product/" + id,

    {
      headers: headers2,
    }
  );
};
const updateGiftById = (values, id) => {
  return axios.post(API_URL + `update-product/${id}`, values, {
    headers: headers,
  });
};

const addGift = (values) => {
  return axios.post(API_URL + `product`, values, {
    headers: headers,
  });
};

const getOrders = (page, limit, values, status, year) => {
  return axios.get(
    API_URL +
      `orders/${year}/${status}?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleOrders = (id, admin_id) => {
  return axios.delete(API_URL + "order/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllOrders = (admin_id) => {
  return axios.delete(API_URL + "orders", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const archiveAllOrders = (admin_id) => {
  return axios.put(API_URL + "archive-all-orders", {
    headers: headers2,
    // data: {
    adminId: admin_id,
    // },
  });
};

// voucher 
const getVouchers = (page, limit, values, status, year) => {
  return axios.get(
    API_URL +
      `vouchers/${year}/${status}?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleVouchers = (id, admin_id) => {
  return axios.delete(API_URL + "voucher/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};

const voucherArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `archive-voucher/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};
const voucherUnArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `unarchive-voucher/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const updateVoucherById = (values, adminId, id, choices) => {
  return axios.put(
    API_URL + `voucher/${id}`,
    {
      company: values.Company,
      job: values.Job,
      certificate: values.Certificate,
      expiration: values.Expiration,
      concatenated: values.Concatenated,
      amount: values.Amount,
      shipCost: values.shipCost,
      shipped: values.Shipped,
      received: values.Received,
      fulfilled: values.Fulfilled,

      fname: values.First,
      lname: values.Last,
      street: values.Street,
      city: values.City,
      state: values.State,
      zip: values.Zip,
      issuance: values.issuance,
      denomination: values.Denomination,
      choice: choices,
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

// EMAILS APIS
const getEmails = (page, limit, values) => {
  return axios.get(
    API_URL +
      `emails?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleEmail = (id, admin_id) => {
  return axios.delete(API_URL + "email/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllEmail = (admin_id) => {
  return axios.delete(API_URL + "emails", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const getEmailById = (id) => {
  return axios.get(
    API_URL + "email/" + id,

    {
      headers: headers2,
    }
  );
};
const updateEmailById = (email, id, adminId) => {
  return axios.put(
    API_URL + `email/${id}`,
    {
      email: email,
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};

const addEmail = (email, adminId) => {
  return axios.post(
    API_URL + `email`,
    {
      email: email,
      adminId: adminId,
    },
    {
      headers: headers,
    }
  );
};

// logos section .

const getLogos = (page, limit, values) => {
  return axios.get(
    API_URL +
      `logos?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const deletSingleLogos = (id, admin_id) => {
  return axios.delete(API_URL + "logo/" + id, {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const deletAllLogos = (admin_id) => {
  return axios.delete(API_URL + "logos", {
    headers: headers2,
    data: {
      adminId: admin_id,
    },
  });
};
const getLogoById = (id) => {
  return axios.get(
    API_URL + "logo/" + id,

    {
      headers: headers2,
    }
  );
};
const updateLogoById = (values, id) => {
  return axios.post(API_URL + `logo/${id}`, values, {
    headers: headers,
  });
};

const addLogo = (values) => {
  return axios.post(API_URL + `logo`, values, {
    headers: headers,
  });
};

const getVoucherById = (id) => {
  return axios.get(
    API_URL + "voucher/" + id,

    {
      headers: headers2,
    }
  );
};

// job api

const getJobss = (page, limit, values, year) => {
  return axios.get(
    API_URL +
      `jobs/${year}?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};

const getJobDetailByJob = (page, limit, values, job) => {
  return axios.get(
    API_URL +
      `job-detail/${job}?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};
const getJobDetailInfoByJob = (job) => {
  return axios.get(API_URL + `job-detail-info/${job}`, {
    headers: headers2,
  });
};

const uploadSheet = (values) => {
  return axios.post(API_URL + `upload-sheet`, values, {
    headers: headers,
  });
};

const getSubmittedSurvey = (page, limit, values, status) => {
  return axios.get(
    API_URL +
      `surveys/${status}?limit=${limit}&page=${page}${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }`,
    {
      headers: headers2,
    }
  );
};

const surveyArchive = (adminId, orderId) => {
  return axios.put(
    API_URL + `archive-survey/${orderId}`,
    {
      adminId: adminId,
    },
    {
      headers: headers2,
    }
  );
};
const getNewsletter = (orderId) => {
  return axios.get(
    API_URL + `newsletter/${orderId}`,

    {
      headers: headers2,
    }
  );
};

const newsletterUpdate = (values, admin_id, id) => {
  return axios.put(
    API_URL + "newsletter/" + id,
    {
      subject: values.subject,
      body: he.encode(values.body),
      adminId: admin_id,
    },
    {
      headers: headers2,
    }
  );
};

const AdminListService = {
  loginAdmin,
  forgotPass,
  getCounts,
  latestOrder,
  getProfile,
  profileUpdate,
  orderArchive,
  getWebsiteInfo,
  websiteUpdate,
  getGifts,
  getAllGifts,
  getAllYears,
  getAllLogos,
  deletSingleGifts,
  deletAllGifts,
  getGiftById,
  updateGiftById,
  addGift,
  getOrders,
  deletSingleOrders,
  deletAllOrders,
  archiveAllOrders,
  voucherUnArchive,
  updateVoucherById,
  deletSingleVouchers,
  getVouchers,
  voucherArchive,
  getVoucherById,
  getEmails,
  deletAllEmail,
  deletSingleEmail,
  getEmailById,
  updateEmailById,
  addEmail,
  getLogos,
  deletSingleLogos,
  deletAllLogos,
  getLogoById,
  updateLogoById,
  addLogo,
  getJobss,
  getJobDetailByJob,
  getJobDetailInfoByJob,
  uploadSheet,
  getSubmittedSurvey,
  surveyArchive,
  getNewsletter,
  newsletterUpdate,
};

export default AdminListService;
