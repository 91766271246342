import React, { useEffect, useState } from "react";
import Sidebar from "../includes/Sidebar";
import MainHeading from "../includes/MainHeading";
import Footer from "../includes/Footer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ReactLoading from "react-loading";
import { addLogoFun } from "../../../schema";
import Test from "../ManageDocument/Test";

export default function Editlogo() {
  const [loading, setLoading] = useState(false);

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();
  const [file, setFile] = useState("");

  useEffect(() => {
    if (adminAccessToken) {
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const handleFileChange = async (e) => {
    setFile(e.target.files[0]);
    setFieldValue("image", e.target.value);
  };

  const addLogoFunCal = async () => {
    const formData = new FormData();
    if (file !== "") {
      formData.append("image", file);
    }

    formData.append("adminId", parseInt(admin_id));

    setLoading(true);
    try {
      const response = await AdminListService.addLogo(formData);

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/admin/manage-logo", { replace: true });
      }, 1000);
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.data?.image?.length) {
        toast.error(err?.response?.data?.image[0], {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      image: "",
    },
    validationSchema: addLogoFun,
    onSubmit: (values) => {
      addLogoFunCal();
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Update Logo" />

          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <h4 className="mb-0">Update Logo</h4>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-md-12">
                      <div className=" file-area">
                        <input
                          type="file"
                          name="images"
                          id="images"
                          required="required"
                          accept=".png,.jpg,.jpeg,.webp"
                          onChange={handleFileChange}
                        />
                        <div className="file-dummy">
                          <div className="success">
                            Great, your logo is selected. Keep on.
                          </div>
                          <div className="default">Choose Logo</div>
                        </div>
                      </div>
                      {errors.image && touched.image ? (
                        <span className="text-danger">{errors.image}</span>
                      ) : null}
                    </div>

                    <div className="col-12">
                      <button className="btn btn-primary mt-3" type="submit">
                        Add Logo
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {/* {loading && (
        <ReactLoading
          type="spinningBubbles"
          color="#f68d2d"
          className="bar-loader"
        />
      )} */}
      {loading && <Test progress="100" />}
    </div>
  );
}
